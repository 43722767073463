
/**
 * @name: 商品管理-商品分类管理
 * @author: itmoai
 * @date: 2023-12-07 16:39
 * @description： 商品管理-商品分类管理
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {
  productSortCreateApi,
  productSortDetailApi,
  productSortModifyApi,
  productSortQueryApi
} from "@/apis/product/sort"
import config from "@/config";
import { IProductSort } from "@/apis/product/sort/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {IPage} from "@/apis/page";

@Component({})
export default class productSort extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IProductSort[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPage = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    delBtn: false,
    dialogWidth: '600px',
    column: [
      {
        label: "分类名称",
        prop: "sortName",
        align: "center",
        maxlength: 20,
        rules: [
          {required: true, message: "分类名称不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
                return callback(new Error('只支持中文、字母、数字'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        label: "排序",
        prop: "sort",
        align: "center",
        type: "number",
        rules: [
          {required: true, message: "排序不能为空", trigger: "blur"},
          {
            validator: (rule: any, value: string, callback: Function) => {
              if (!value || value == '0') {
                return callback(new Error('排序不能为空和0'))
              }
              if (!/^[0-9]*[1-9][0-9]*$/.test(value)) {
                return callback(new Error('排序只能正整数'))
              }
              if (parseInt(value) > 99) {
                return callback(new Error('排序最大99'))
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      {
        label: "分类图片",
        prop: "sortPic",
        type: "image",
        align: "center",
        imgPrefix: config.downloadUrl,
        rules: [
          {required: true, message: "分类图片不能为空", trigger: "blur"}
        ],
        addSlot: true,
        editSlot: true
      },
      {
        label: "状态",
        prop: "sortStatus",
        align: "center",
        addHide: true,
        editHide: true,
        slot: true
      }
    ]
  }

  getList () {
    this.tableLoading = true
    productSortQueryApi(this.queryParam).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  beforeUpload (file: File) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 < 120;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPG、JPEG、PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 120KB!');
    }
    return isJPG && isLt2M;
  }

  rowSave (form: IProductSort, done: Function, loading: Function) {
    productSortCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: IProductSort, done: Function, loading: Function) {
    productSortModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }


  statusChange (productSortId: string, sortStatus: number) {
    this.tableLoading = true
    productSortModifyApi({productSortId, sortStatus}).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      this.tableLoading = false
    })
  }

  openEdit (row: IProductSort, index: number) {
    productSortDetailApi(row.productSortId).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
      }
    })
  }

  created () {
    this.getList()
  }
}
