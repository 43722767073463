/**
 * @name: 商品管理-商品分类管理接口文件
 * @author:
 * @date: 2023-12-07 16:39
 * @description： 商品管理-商品分类管理接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPage, IPageRes} from "@/apis/page";
import {IProductSort} from "@/apis/product/sort/types";

/**
 * 商品分类分页查询
 * @param param 查询参数
 */
export const productSortQueryApi = (param: IPage) => get<IPageRes<IProductSort[]>>("/card/productSort/query", param)
/**
 * 创建
 * @param data
 */
export const productSortCreateApi = (data: Partial<IProductSort>) => postJ("/card/productSort/create", data)
/**
 * 修改
 * @param data
 */
export const productSortModifyApi = (data: Partial<IProductSort>) => postJ("/card/productSort/modify", data)
/**
 * 详情
 * @param id
 */
export const productSortDetailApi = (id: string) => get<IProductSort>("/card/productSort/detail/" + id)
